import './App.css';

function Header() {
  return (
    <div className="App-header">
        <a href="https://www.armnleg.com" ><p>Home</p> </a>
        <a href="https://www.armnleg.com/about" ><p>About</p> </a>
        

    </div>
  );
}

export default Header;